// Utiliy functions file used across whole app.

/*  Table of contents:

    1. String Manipulation
    2. Number Manipulation (ints,floats)
    3. Sorting functions
    4. Date/Time Manipulation
    5  Validation
    6. Misc

*/

/* String Manipulation */

// Uppercase first character in string.
export const upperCaseFirstChar = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// Convert camel case to readable text (eg. camelCase -> Camel Case).
export const camelCaseToLabel = (str) => {
  return (
    str // insert a space before all caps
      .replace(/([A-Z])/g, " $1")
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
};

// Convert hyphen separated to readable text (eg. hyphen-separated -> Hyphen Separated).
export const hyphenToLabel = (str) => {
  return (
    str
      // Replace hyphens with space
      .replace(/-/g, " ")
      // Uppercase the first letter of every word.
      .replace(/\b\w/g, (l) => l.toUpperCase())
  );
};

/* Number Manipulation */

// Return number with commas.
export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

export const setTwoDecimals = (x) => {
  if (x === "") {
    return parseFloat(0).toFixed(2);
  }
  return parseFloat(x).toFixed(2);
};

export const setDecimals = (x, places = 0) => {
  if (x === "") {
    return parseFloat(0).toFixed(places);
  }
  return numberWithCommas(parseFloat(x).toFixed(places));
};

// Converts string miliseconds to number of days.
export const milisecondsToDays = (x) => {
  if (x === "") {
    return "";
  }

  return parseInt(x) / 86400000;
};

// Converts number of days into string of miliseconds.
export const daysToMiliseconds = (x) => {
  if (x === "") {
    return "";
  }

  return (x * 86400000).toString();
};

export const addZeroesToNumber = (n) => {
  if (n === "") {
    return "";
  }

  return n < 10 ? `0${n}` : n;
};

/* Sorting Functions */

// Sort by active/inactive.
export const sortByActive = (array, activeToggle) => {
  return array.sort((a, b) => {
    if (a.isActive && !b.isActive) {
      return activeToggle ? -1 : 1;
    }
    if (!a.isActive && b.isActive) {
      return activeToggle ? 1 : -1;
    }
    return 0;
  });
};
//
export const sortByString = (array = [], key = "name") => {
  if (array.length === 0) {
    return [];
  }

  return [...array].sort((a, b) => {
    let nameA = a[key]?.hasOwnProperty("subtext")
      ? a[key].text?.toLowerCase()
      : a[key]?.hasOwnProperty("onClick")
      ? a[key].data?.toLowerCase()
      : a[key]?.toLowerCase();

    let nameB = b[key]?.hasOwnProperty("subtext")
      ? b[key].text?.toLowerCase()
      : b[key]?.hasOwnProperty("onClick")
      ? b[key].data?.toLowerCase()
      : b[key]?.toLowerCase();

    if (nameA > nameB) {
      return 1;
    }
    if (nameA < nameB) {
      return -1;
    }
    return 0;
  });
};

export const sortByBoolean = (array, key) => {
  // true values first
  return array.sort((a, b) => {
    return Number(b[key]) - Number(a[key]);
    // a[key] === b[key] ? 0 : a ? -1 : 1;
  });
};

export const sortByNumber = (array, key) => {
  return array.sort((a, b) => {
    // Handle numbers in string form:
    if (typeof a[key] === "string" && typeof b[key] === "string") {
      if (a[key].includes("%")) {
        return parseFloat(a[key].substr(0, a[key].length - 2)) >
          parseFloat(b[key].substr(0, b[key].length - 2))
          ? -1
          : 1;
      }

      if (a[key].includes("$")) {
        return parseFloat(a[key].substr(1)) > parseFloat(b[key].substr(1))
          ? -1
          : 1;
      }

      return parseFloat(a[key]) > parseFloat(b[key]) ? -1 : 1;
    }

    return a[key] > b[key] ? -1 : 1;
  });
};

// Sort array elements by date.
export const sortByDate = (inputArray, key = "effectiveDate") => {
  let array = [...inputArray];

  return array.sort((a, b) => {
    if (!isNaN(a[key]) && !isNaN(b[key])) {
      if (a[key] > b[key] || a[key] === "") {
        return -1;
      }
      if (b[key] > a[key] || b[key] === "") {
        return 1;
      }
    } else if (
      new Date(a[key]) instanceof Date &&
      new Date(b[key]) instanceof Date
    ) {
      return new Date(b[key]) - new Date(a[key]);
    }

    return 0;
  });
};

/* Date/Time Manipulation */

export const formatDate = (date) => {
  // let dateString = new Date(`${date}T00:00`).toLocaleString("en-US", {
  //   timeZone: "America/New_York",
  //   year: "numeric",
  //   month: "2-digit",
  //   day: "2-digit",
  // });

  const [month, day, year] = date.split("/");
  // console.log(year);

  // let year = dateString.getFullYear();
  // console.log(year);
  // let month = etDate.getMonth() + 1;
  // // Add extra to month 0 if neccessary.
  // if (month < 10) {
  //   month = `0${month}`;
  // }

  // let day = etDate.getDate();
  // // Add extra 0 to day if neccessary.
  // if (day < 10) {
  //   day = `0${day}`;
  // }

  return `${year}-${month}-${day}`;
};

export const formatTime = (hours, minutes) => {
  let h = hours;
  let m = minutes;
  if (h < 10) {
    h = `0${hours}`;
  }
  if (m < 10) {
    m = `0${minutes}`;
  }

  return h + ":" + m;
};

// Returns readable date from unix epoch timestamp.
export const getReadableDate = (input, includeTime = false) => {
  if (input === "" || input === 0 || input === "0") {
    return "";
  }
  let dateString = new Date(input * 1000).toLocaleString("en-US", {
    timeZone: "America/New_York",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    ...(includeTime ? { hour: "2-digit", minute: "2-digit" } : {}),
  });

  // let date = new Date(dateString);

  // let year = date.getFullYear();
  // let month = date.getMonth() + 1;
  // let day = date.getDate();

  // if (includeTime) {
  //   let time = `${date.getHours()}:${
  //     date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
  //   }:${date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()}`;

  //   return month + separator + day + separator + year + " " + time;
  // }

  return dateString.replace(/,/g, "");
  // month + separator + day + separator + year;
};

// Returns date value used in inputs from unix epoch timestamp.
export const getDateValue = (input, separator = "-") => {
  // Case for newly added row with no input.
  if (input === "" || input === 0 || input === "0") {
    return "";
  }
  let date = new Date(input * 1000).toLocaleString("en-US", {
    timeZone: "America/New_York",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  return formatDate(date);
};

export const getCurrentDate = () => {
  let dateString = new Date().toLocaleString("en-US", {
    timeZone: "America/New_York",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  let currentDate = new Date(formatDate(dateString) + "T00:00");

  let currentHours =
    currentDate.getHours() < 10
      ? "0" + currentDate.getHours()
      : currentDate.getHours();

  let currentMinutes =
    currentDate.getMinutes() < 10
      ? "0" + currentDate.getMinutes()
      : currentDate.getMinutes();

  let today = currentDate.toLocaleString("en-US", {
    timeZone: "America/New_York",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  return { date: today, time: currentHours + ":" + currentMinutes };
};

export const dateToUnix = (input, formatted = false) => {
  return formatted
    ? Math.floor(new Date(`${input}T00:00`).getTime() / 1000)
    : Math.floor(new Date(`${formatDate(input)}T00:00`).getTime() / 1000);
};

export const roundMinutes = (minutes) => {
  return (Math.round(minutes / 15) * 15) % 60;
};

/* Validation */
export const checkUUID = (uuid) => {
  let uuidCheck = new RegExp(
    `^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$`
  );
  return uuidCheck.test(uuid);
};

export const checkEmail = (email) => {
  let emailCheck = new RegExp(
    `^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$`
  );
  return emailCheck.test(email);
};

export const checkURL = (url) => {
  let urlCheck = new RegExp(
    `https?://(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}([-a-zA-Z0-9()@:%_+.~#?&//=]*)`
  );

  return urlCheck.test(url);
};

export const checkRegex = (text, regex) => {
  let regexCheck = new RegExp(`${regex}`);
  return regexCheck.test(text);
};

/* Misc */

// Get current path.
export const getLocation = (pathname) => {
  return pathname?.substr(pathname.lastIndexOf("/") + 1);
};

//Print Array
export const printArray = (arr) => {
  if (!arr.length) {
    return "";
  }
  return arr.join(",\n");
};

// Randomly generated email
export const getRandomEmail = () => {
  let chars = "abcdefghijklmnopqrstuvwxyz1234567890";
  let str = "";
  for (let i = 0; i < 15; i++) {
    str += chars[Math.floor(Math.random() * chars.length)];
  }
  return str + "@gmail.com";
};

export const getObjectDiff = (o1, o2) => {
  let beforeObj = {};
  let afterObj = {};

  Object.keys(o2).forEach((key, idx) => {
    // console.log(o1[key]);
    // console.log(o2[key]);

    // If objects aren't equal:
    if (JSON.stringify(o2[key]) !== JSON.stringify(o1[key])) {
      // Handle Array case:
      if (Array.isArray(o1[key]) && Array.isArray(o2[key])) {
        let beforeArr = new Array(
          Math.max(o1[key].length, o2[key].length)
        ).fill("unchanged");
        let afterArr = new Array(Math.max(o1[key].length, o2[key].length)).fill(
          "unchanged"
        );
        (o1[key].length >= o2[key].length ? o1[key] : o2[key]).forEach(
          (j, i) => {
            let o1elem = o1[key][i] === undefined ? "{}" : o1[key][i];
            let o2elem = o2[key][i] === undefined ? "{}" : o2[key][i];

            let innerResult = getObjectDiff(o1elem, o2elem);
            if (innerResult !== null) {
              beforeArr[i] = innerResult.before;
              afterArr[i] = innerResult.after;
            }

            if (beforeArr.length > 0 || afterArr.length > 0) {
              beforeObj[key] = beforeArr;
              afterObj[key] = afterArr;
            }
          }
        );
      }
      // Handle object:
      else if (typeof o1[key] === "object" && typeof o2[key] === "object") {
        let innerResult = getObjectDiff(o1[key], o2[key]);
        if (innerResult === null) {
          return;
        }
        beforeObj[key] = Object.values(innerResult.before).join("");
        afterObj[key] = Object.values(innerResult.after).join("");
      }
      // Handle primitive value case:
      else {
        beforeObj[key] = o1[key];
        afterObj[key] = o2[key];
      }
    }
  });

  if (Object.keys(afterObj).length === 0) {
    return null;
  }

  return {
    before: beforeObj,
    after: afterObj,
  };
};

export const isValidJson = (str) => {
  try {
    JSON.parse(str);
  } catch (err) {
    return false;
  }
  return true;
};
