import { useEffect, useState } from "react";

// useFilter Hook
const useFilter = (data, fields) => {
  // Filter state variables
  const [filter, setFilter] = useState("");

  // Array of filtered data.
  const [filteredData, setFilteredData] = useState([...data]);
  useEffect(() => {
    if (data) {
      setFilteredData(data);
      // setFilter("");
    }
  }, [data]);

  // Function to pass to the Filter component (../components/Filter.js)
  // const updateFilter = async (input) => {
  //   // Return data that include the input.
  //   const res = data.filter((element) => {
  //     // Support for single-nested fields:
  //     let elem = field.includes(".")
  //       ? element[field.split(".")[0]][field.split(".")[1]]
  //       : element[field];

  //     if (elem?.hasOwnProperty("text")) {
  //       return elem.text.toLowerCase().includes(input.toLowerCase());
  //     }
  //     if (elem?.hasOwnProperty("onClick")) {
  //       return elem.data.toLowerCase().includes(input.toLowerCase());
  //     }
  //     return elem?.toLowerCase().includes(input.toLowerCase());
  //   });

  //   // Setting the state.
  //   setFilter(input);
  //   setFilteredData(res);
  // };

  const updateFilter = async (input) => {
    // Return data that includes the input.
    const res = data.filter((element) => {
      let included = false;
      fields.forEach((field) => {
        let elem = field.includes(".")
          ? element[field.split(".")[0]][field.split(".")[1]]
          : element[field];

        if (elem?.hasOwnProperty("text")) {
          included =
            included || elem.text.toLowerCase().includes(input.toLowerCase());
        } else if (elem?.hasOwnProperty("onClick")) {
          included =
            included || elem.data.toLowerCase().includes(input.toLowerCase());
        } else {
          included =
            included ||
            (elem &&
              elem.toString().toLowerCase().includes(input.toLowerCase()));
        }
      });
      return included;
    });
    // Setting the state.
    setFilter(input);
    setFilteredData(res);
  };

  return { filter, updateFilter, filteredData };
};

export default useFilter;
